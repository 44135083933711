@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Regular.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Italic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Thin-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-ThinItalic-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-ExtraLight-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-ExtraLightItalic-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Light-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-LightItalic-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Medium.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-MediumItalic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-SemiBoldItalic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Bold.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-BoldItalic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-ExtraBold.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-ExtraBoldItalic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Black.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-BlackItalic.woff) format('woff');
    font-display: swap;

}
@font-face {
    font-family: "Recoleta";
    src: local("Recoleta"),
    url("./themes/Mential font/634ab12299023664817c1ec0_Mential-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Recoleta";
    src: local("Recoleta"),
    url("./themes/Mential font/6349ec910007a158ad11acf1_Recoleta-Bold.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
}



.App-logo {
    height: 40vmin;
    poInter-events: none;
}

html {
    font-family: "Inter";


}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

body {
    background-color: #FAFCFE;
}

input {
    /* Text/Tiny/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;

    /* identical to box height, or 18px */

    /* Blumine Muted */
    color: #485E75;
}

.MuiContainer-root{
    background-color: #FAFCFE;
    min-height: 650px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

h2 {
    margin-bottom: 8px;
    margin-top: 0px;
}

p {
    margin: 0px
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    margin: auto
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    background-color: #ffffff;
    font-family: "Inter";


}

.App {
    background-color: #ffffff;
    font-family: "Inter";
}


.MuiToggleButtonGroup-root {

}

.Mui-selected {
    font-weight: bold;
    font-family: "Inter";
    background-color: #C1D3E6;


}

.MuiToggleButton-root {
    font-weight: bold;
    font-family: "Inter";
    text-transform: none !important;


}


.MuiInputBase-root .MuiInputBase-input {
    /* Gray */

    min-height: 35px;
       padding: 5px 5px 5px 20px;
    border-radius: 16px;
    font-size: 0.85rem;


   }

.MuiAutocomplete-root .MuiAutocomplete-input legend{
    font-family: "Inter";
    font-size: 0.81rem;
    border-radius: 16px;
    min-height: 35px;
    padding: 5px 5px 5px 20px;
}



   .MuiOutlinedInput-notchedOutline{
       border-radius: 0px;
       font-size: 0.81rem;
       font-family: "Inter";

   }



   .MuiAutocomplete-root {
       border-radius: 64px !important;

   }
   .MuiFormControl-root label{
       font-size: 0.85rem;
       color: #aaacad;

   }

   .MuiFormControl-root{

       border: 0px !important;

   }



   .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
       min-height: 35px;
   }

   .MuiInputBase-input:focus {
       /* Gray
       border: 2px solid #3248CA;
       border-radius: 64px;*/

}

.MuiSelect-select {
    min-height: 35px !important;
    padding: 12px 30px 0px 15px !important;

}

.mentialInput:focus {

    border: 2px solid #3248CA;
    border-radius: 64px;

}
.TextField-with-border{
    border-radius: 64px;
    border: 1px solid #d2e3f4 !important;
    min-height: 50px;
    max-height: 52px;
    display: flex;
    align-items: center;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    background: white;

}

.TextField-with-border:focus-within{
    border-radius: 64px;
    border: 2px solid #C1D3E6 !important;
}
.MuiInputBase-input-MuiOutlinedInput-input:focus {

    border: 1px solid hsla(210, 72%, 14%, 50%);
    border-radius: 64px !important;

}

.MuiBottomNavigationAction-label.Mui-selected{
    background-color: transparent;
}

.MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 64px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-radius: 64px !important;
}


.MuiAutocomplete-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
    max-height: 50px;
}
.MuiMenuItem-root {
    font-family: "Inter";
    border: 1px solid rgb(231, 232, 232);
    font-size: 0.81rem;

}

.MuiMenu-list li{
    font-family: "Inter";
    border: 1px solid rgb(231, 232, 232);
    font-size: 0.81rem;

}

.MuiAutocomplete-input{
    padding: 0px !important;
}

.Assessment-header {
    width: 716px;
    height: 45px;

    /* Heading/xxlarge */
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;

    /* or 67px*/
    text-align: center;

    /* Blumine */
    color: #0A243E;

}

.Assessment-header-second-size {
    /* Heading/xxlarge */
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 41.6px;

    text-align: center;


}

.Assessment-header-small {

    /* Heading/xxlarge */
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;

    /* or 67px */
    text-align: center;

    /* Blumine */
    color: #0A243E;

}

.MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
}

.Assessment-description {
    width: 656px;
    height: 26px;

    /* Text/Large/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;

    /* or 28px */
    text-align: center;

    /* Blumine Muted */
    color: #485E75;
}

.MuiAccordionSummary-content {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;

}

@media (max-width: 600px) {

    .MuiAccordionSummary-content {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;

    }
}


.MuiAccordionDetails-root {
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;
    width: 200%;
    position: relative;
    right: 50%;
}

.accordeon-items-label {
    padding: 8px;
    font-size: 15px;
    font-weight: 400;
    /* Blumine Muted */
    color: #485E75;
    margin: 0px 10px;

}


.step_image_full {
    display: block;
}

.step_image_mobile {
    display: none;
}

.buttonContinueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0px 0px;
    gap: 24px;

    position: absolute;
    left: 40.83%;
    right: 45.83%;
    top: calc(50% - 66px / 2 + 360px);
}

.simpleInput-label {
    /* Text/Tiny/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;

    /* identical to box height, or 18px */

    /* Blumine Muted */
    color: #485E75;
}

.simpleInput-label-accordeon-sumary {
    /* Text/Tiny/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;

    /* identical to box height, or 18px */

    /* Blumine Muted */
    color: #485E75;
}

.buttonContinue {

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;

    max-width: 200px;
    min-width: 200px;
    height: 50px;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    /* Primrose pure */
    background: #3248CA;
    border-radius: 64px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
}

.mentialButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: white;
    text-transform: none;

}


.buttonContinueFullWidth {

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;


    height: 50px;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    /* Primrose pure */
    background: #3248CA;
    border-radius: 64px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;

}

.li-padding{
    padding:4px 0px 4px 8px
}

@media (max-width: 850px) {

    .MuiAccordionDetails-root {
        width: 150%;
        right: 25%;
    }
    .li-padding{
        padding:2px 0px 2px 8px
    }
}

@media (max-width: 600px) {
    .Assessment-header {
        text-align: center;
        font-size: 42px;

    }

    .Assessment-header-small {
        text-align: left;
    }

    .Assessment-description {
        text-align: left;
    }

    .Assessment-header-second-size {
        text-align: left;
        font-size: 32px;


    }

    .buttonContinue {
        width: 100%;
        max-width: 85%;
    }

    .buttonLink {
        width: 90%;
    }

    .step_image_full {
        display: none;
    }

    .step_image_mobile {
        display: block;
    }

    .MuiAccordionDetails-root {
        width: 100%;
        right: 0%;
    }


}

.goBackButton {
    position: absolute;
    left: 5%;
    top: 3%;
}

.goBackButton:hover {
    cursor: poInter;
}

.TextField-with-border fieldset {
    border: 0;
    font-size: 0.81rem;
}

.MuiFormHelperText Mui-error {
    margin-left: 10px;
}

.MuiInputLabel-root.Mui-error{
    font-size: 0.81rem;
}

.HeadingLarge {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 48px;
    color: #0A243E
}
.HeadingSmall {
font-family: 'Recoleta';
font-weight: 400;
font-size: 24px;
line-height: 33px;
color: #0A243E
}
@media (max-width: 900px) {

    .HeadingSmall {
        /*HeadingXsmall*/
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #0A243E
    }

}

@media (max-width: 900px) {

    .HeadingLarge {
        /*HeadingMedium*/
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 2rem;
        line-height: 41.8px;
        color: #0A243E
    }
}

@media (max-width: 400px) {

    .HeadingLarge {
        /*HeadingMedium*/
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 41.8px;
        color: #0A243E
    }
}

.HeadingXLarge {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 48px;
    line-height: 57.6px;
    color: #0A243E
}

@media (max-width: 600px) {
    .HeadingXLarge {
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        color: #0A243E
    }
}

.HeadingMedium {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    color: #0A243E
}

@media (max-width: 600px) {

    .HeadingMedium {
        /* HeadingSmall */
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #0A243E
    }
}

.HeadingXxlarge {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 67.2px;
    color: #0A243E
}

@media (max-width: 700px) {
    .HeadingXxlarge {
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 48px;
        color: #0A243E
    }
}

@media (max-width: 400px) {
    .HeadingXxlarge {
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        color: #0A243E
    }
}


.HeadingXsmall {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #0A243E
}

@media (max-width: 900px) {
    .HeadingXsmall {
        font-family: 'Recoleta';
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: #0A243E
    }
}

.HeadingXXsmall {
    font-family: 'Recoleta';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A243E
}

.TextMediumNormal {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 17px;
    line-height: 25.5px;
    color: #485E75
}

@media (max-width: 900px) {

    .TextMediumNormal {
        /*TextRegularNormal*/
        font-family: 'Inter';
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #485E75
    }
}

@media (max-width: 400px) {

    .TextMediumNormal {
        /*TextRegularNormal*/
        font-family: 'Inter';
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextMediumMedium {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 17px;
    line-height: 25.5px;
    color: #485E75
}

@media (max-width: 400px) {

    .TextMediumMedium {
        /*TextRegularNormal*/
        font-family: 'Inter';
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextMediumSemibold {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #485E75
}

.TextLargeMedium {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 19px;
    line-height: 28.5px;
    color: #485E75
}

@media (max-width: 900px) {
    .TextLargeMedium {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 17px;
        line-height: 25.5px;
        color: #485E75
    }
}

.TextRegularNormal {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

@media (max-width: 400px) {
    .TextRegularNormal {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 0.80rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextRegularMedium{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

.TextSmallNormal {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

.TextSmallSemibold {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

@media (max-width: 600px) {
    .TextSmallNormal {
        /*TextTinyNormal */
        font-family: 'Inter';
        font-weight: 400;
        font-size: 0.75rem;
        color: #485E75
    }
}

.TextSmallMedium {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

.TextSmallLight {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

.TextTinyNormal {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.71rem;
    color: #485E75
}

.TextTinyLight {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 0.71rem;
    color: #485E75
}

.TextTinyMedium {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.81rem;
    color: #485E75
}

.TextTinySemibold {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 0.71rem;
    color: #485E75
}

.TextRegularLight {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

.TextRegularSemibold {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}


.howfaceitDisplay{
    display: flex
}

@media (max-height: 770px){
    .howfaceitDisplay{
        display: none
    }
}

.StandardInput {
    width: 100%;
    box-shadow: none;
    min-height: 60px;
    font-size: 16px;
    margin-left: 10px;
    font-family: "Inter";
    padding: 10px;
    background: #d3dddd;
    border: 0px;
}

.ul-list-inline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.fullWidth {
    width: 100%;
}

.MuiFormControlLabel-label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.81rem !important;
    line-height: 18px;
    color: #0A243E
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollContentNotes::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollContentNotes {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.box-with-border{
    border: 1px solid #D1D5DB;
    border-radius: 12px;
    background:white;
}

.box-with-border-gray{
    border: 1px solid #D1D5DB;
    border-radius: 12px;
    background: #f7f9fc;
}

.box-with-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.mential-input  {
    border-radius: 16px !important;
    background: #f7f9fc !important;
}

.pn-msg-list {
    --msg-list__background: #FFFFFF;
    min-height: 50vh;
}
.pn-msg-list-scroller {
    height: 50vh !important;
    overflow-y: scroll !important;

}

.pn-msg-input {
    --msg-input__background: #E3F0FF;
    border-radius: 16px;
}

.pn-msg-input__textarea {
    --msg-input__textarea__background: #E3F0FF;
    --msg-input__textarea__height: 40px;
}

.transitionedParagraph span{
    display: block;
    animation: reveal 0.5s forwards var(--d);
}

.transitionedButton button{
    display: block;
    animation: reveal 0.5s forwards var(--d);
}

.transitionedContainer{
    display: block;
    animation: reveal 0.5s forwards var(--d);
}

@keyframes reveal {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.box-animated {
    align-self: flex-end;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
    width: 10px;
}

.bounce-4 {
    animation-name: bounce-4;
    animation-timing-function: ease;
}
@keyframes bounce-4 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-50px); opacity: 80% }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-7px); }
    100% { transform: scale(1,1)    translateY(0); }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0px;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.password-checklist{
    font-family: Inter;
    font-size: 0.83rem;
}

.password-checklist li{
    align-items: center;
}

.codinfox-changing-keywords{
    margin-top: 10px;
    vertical-align:top;
    overflow:hidden;
    height:20px;
    position:relative;
    display: inline-block;
    width: 350px;
}
.hidden{
    position:absolute;
    top: 20px;
    display:inline-block;
    width:350px;
    opacity:0;
}
.hidden:nth-child(1){
    animation: slideme 6s ease-in-out 0s 1 normal forwards;
}
.hidden:nth-child(2){
    animation: slideme 8s ease-in-out 6s 1 normal forwards;
}
.hidden:nth-child(3){
    animation: slideme 12s ease-in-out 14s infinite normal forwards;}

@keyframes slideme {
    0% {
        top: 20px;
        opacity:0;
    }
    5% {
        top: 0px;
        opacity:1;
    }
    10%{
        top : 0;
        opacity:1;
    }
    20%{
        opacity:1;
    }
    70%{
        opacity:1;
    }
    95% {
        opacity:0.1;
        top : 0;
    }
    100% {
        opacity:0;
        top: 20px;
    }
}

.cs-message-input__content-editor-wrapper {
    background: #fafcfe;
}

.react-chatbot-kit-chat-container {
    background: #fafcfe;
    width: 100% !important;
}


.react-chatbot-kit-chat-message-container {
    overflow-x: hidden !important;
    height: 500px !important;
    overflow: auto !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    background: rgb(250, 252, 254) !important;
    max-width: 800px !important;
    min-width: 800px !important;
}


@media (max-width: 600px) {
    .react-chatbot-kit-chat-message-container {
        height: 75vh !important;
        min-width: 300px !important;
        padding: 0 !important;
    }
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
    display: none !important;
}


.react-chatbot-kit-chat-bot-message{
    margin-left: 10px !important;
    padding: 1rem 1.5rem !important;
    margin: auto 7px auto 0px !important;
    display: none !important;
;

}

.react-chatbot-kit-chat-input-container{
    display: none !important;
}

.react-chatbot-kit-chat-bot-message{
    background: rgb(237, 241, 250) !important;
    border-radius: 32px 32px 32px 0px !important;
    color: rgb(10, 36, 62) !important;
    width: auto !important;
    max-width: 80% !important;

}


.react-chatbot-kit-chat-bot-message-arrow{
    border-right-color: rgb(237, 241, 250) !important;
    bottom: 0px;
}

.react-chatbot-kit-user-avatar{
    display: none !important;
}

.react-chatbot-kit-user-chat-message {
    background: rgb(10, 36, 62) !important;
    color: white !important;
    border-radius: 24px 24px 24px 24px !important;
    padding: 0.7rem 1.2rem !important;

}

.react-chatbot-kit-user-chat-message-arrow{
    border-left-color: rgb(10, 36, 62) !important;
    bottom: 0px !important;
    display: none !important;


}

.react-chatbot-kit-chat-bot-message-container{
}

.custom-chat-widget{
    background: rgb(237, 241, 250) !important;
    border-radius: 32px 32px 32px 32px !important;
    color: rgb(10, 36, 62) !important;
    width: auto !important;
    max-width: 80% !important;
    padding: 1rem 1.5rem !important;
    font-size: 1rem;
}

@media (max-width: 600px) {

    .custom-chat-widget {

        max-width: 95% !important;
    }

}

.react-chatbot-kit-chat-inner-container
{
    height: auto !important;
}


.MuiTooltip-popper {
    background: white;
}
